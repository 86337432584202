import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"

export const firebaseConfig = {
    apiKey: "AIzaSyCwm5bbFEokpKGHgzjk_MSrPUdbwUh2KyI",
    authDomain: "portfolio-acanoen-fr.firebaseapp.com",
    databaseURL: "https://portfolio-acanoen-fr-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "portfolio-acanoen-fr",
    storageBucket: "portfolio-acanoen-fr.appspot.com",
    messagingSenderId: "209272122766",
    appId: "1:209272122766:web:80663023075b216ed4c8df",
    measurementId: "G-0GQZTGRNT4"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);

export default app;
