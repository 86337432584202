import {useEffect, useState} from "react";
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../api/firebase";
import {Post} from "../../api/models/Post";
import {getTextGradient} from "../../utils/gradients";
import {useNavigate} from "react-router-dom";

export function Blog() {
    const [posts, setPosts] = useState<Post[]>([]);
    const ref = collection(firestore, "posts");
    const navigate = useNavigate();

    useEffect(() => {
        onSnapshot(ref, snapshot => {
            const refPosts = snapshot.docs.map(snap => {
                const data = snap.data();
                return {
                    id: snap.id,
                    title: data.title,
                    slug: data.slug,
                    content: data.content,
                    posted_at: new Date(data.posted_at.seconds * 1000),
                };
            });
            setPosts(refPosts);
        });
    }, []);

    const handleClick = (post: Post) => {
        navigate('/post/' + post.slug);
    }

    return (
        <div className="blog-items mx-10">
            {posts.map((post, index) => (
                <article className="blog-item my-10" key={index}>
                    <h1 key={index} className={`${getTextGradient()} text-3xl font-bold`}>{post.title}</h1>
                    <p className="my-2">
                        <span className="font-bold">Published at: </span>
                        <span className="italic">{post.posted_at.toDateString()} {post.posted_at.getHours()}:{post.posted_at.getMinutes()}</span>
                    </p>
                    <p className="my-2">{post.content.length < 249 ? post.content : post.content.slice(0, 250)+"..."}</p>
                    <button type="button" className="btn btn-accent btn-sm float-right" onClick={() => handleClick(post)}>Read more</button>
                </article>
            ))}
        </div>
    );
}
