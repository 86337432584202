import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {collection, onSnapshot} from "firebase/firestore";
import {Post as BlogPost} from "../../api/models/Post";
import {firestore} from "../../api/firebase";
import {getTextGradient} from "../../utils/gradients";

export function Post() {
    const navigate = useNavigate();
    const { slug } = useParams();
    const [post, setPost] = useState<BlogPost>();
    const ref = collection(firestore, "posts");

    useEffect(() => {
        onSnapshot(ref, snapshot => {
            const refPost = snapshot.docs.find(x => x.data().slug === slug);
            if (refPost && refPost.exists()) {
                const blogPost = refPost.data();
                setPost({
                    id: blogPost.id,
                    title: blogPost.title,
                    slug: blogPost.slug,
                    content: blogPost.content,
                    posted_at: new Date(blogPost.posted_at.seconds * 1000)
                });
            } else {
                navigate('/404');
            }
        });
    }, []);

    return (
        <div className="single-post my-5">
            <h1 className={`${getTextGradient()} text-4xl text-center font-bold my-8`}>{post?.title}</h1>
            <p className="my-2">
                <span className="font-bold">Published at: </span>
                <span className="italic">{post?.posted_at.toDateString()} {post?.posted_at.getHours()}:{post?.posted_at.getMinutes()}</span>
            </p>
            <div className="my-2">{post?.content}</div>
        </div>
    );
}